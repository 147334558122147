import moment from "moment";
import Utils from ".";

const collectClass = (multiClass: [string]) => {
  let makeClass = "";
  multiClass?.forEach((cls: string) => `${makeClass + cls} `);
  return makeClass;
};

const setLocalStorage = (data: any) => {
  const { accessToken, email, name, userId, isEmailVerified } = data;
  const accToken = localStorage.getItem("accessToken");
  const user_email = localStorage.getItem("user_email");
  const user_id = localStorage.getItem("user_id");
  const user_name = localStorage.getItem("user_name");
  const user_isEmailVerified = localStorage.getItem("user_isEmailVerified");

  if (!accToken) {
    localStorage.setItem("accessToken", accessToken);
  }
  if (!user_email) {
    localStorage.setItem("user_email", email);
  }
  if (!user_id) {
    localStorage.setItem("user_id", userId);
  }
  if (!user_name) {
    localStorage.setItem("user_name", name);
  }
  if (!user_isEmailVerified) {
    localStorage.setItem("user_isEmailVerified", isEmailVerified);
  }
};
const momentFunction = (presentTime: any, createdAt: any) => {
  return moment(presentTime).valueOf() - moment(createdAt).valueOf();
};

const convertTimeIntoSec = (presentTime: any, createdAt: any) => {
  return Math.trunc(momentFunction(presentTime, createdAt) / 1000);
};
const convertTimeIntoMin = (presentTime: any, createdAt: any) => {
  return Math.trunc(momentFunction(presentTime, createdAt) / 1000 / 60);
};
const convertTimeIntoHour = (presentTime: any, createdAt: any) => {
  return Math.trunc(momentFunction(presentTime, createdAt) / 1000 / 60 / 60);
};
const convertTimeIntoDay = (presentTime: any, createdAt: any) => {
  return Math.trunc(
    momentFunction(presentTime, createdAt) / 1000 / 60 / 60 / 24
  );
};
const convertTimeIntoWeek = (presentTime: any, createdAt: any) => {
  return Math.trunc(
    momentFunction(presentTime, createdAt) / 1000 / 60 / 60 / 24 / 7
  );
};

export function getFileType(file: string): string {
  let fileType = "";

  if (file && file.includes(".pdf")) {
    fileType = "pdf";
  } else if (file && file.includes(".doc")) {
    fileType = "doc";
  } else if (file && file.includes(".xlsx")) {
    fileType = "xlsx";
  } else if (file && file.includes(".jpg")) {
    fileType = "jpg";
  } else if (file && file.includes(".jpeg")) {
    fileType = "jpeg";
  } else if (file && file.includes(".JPG")) {
    fileType = "JPG";
  } else if (file && file.includes(".jfif")) {
    fileType = "jfif";
  } else if (file && file.includes(".png")) {
    fileType = "png";
  }
  return fileType;
}

let getNotificationTimeStatus = function (date: any) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  let seconds = Math.floor((new Date().valueOf() - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "month";
    } else {
      interval = Math.floor(seconds / 604800);
      if (interval >= 1) {
        intervalType = "week";
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = "day";
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }

  return `${interval} ${intervalType}`;
};

export const getCreatedDate = (date: any) => {
  return moment(date).utc().local().format("DD/MM/YYYY");
};

export const getEndDate = (date: any) => {
  return moment(date).utc().local().subtract(1, "d").format("DD/MM/YYYY");
};

export const getTime = (time: string) => {
  return moment(time).utc().local().format("hh:mm A");
};

const getSeparate = (data: string) => {
  let curr = data;
  let str = "";
  for (let i = 0; i < curr.length; i++) {
    if (i === curr.length - 1) {
      str += curr[i];
    } else {
      str += curr[i];
      str += ", ";
    }
  }
  return str;
};
const getBusinessName = (data: any) => {
  let bName, len;
  if (data.length > 1) {
    len = data.length;
  }
  if (data.length <= 0) return;
  if (data.length > 0 && len > 1) {
    bName = data[0].businessName;
    return `${bName} + ${len - 1} Others`;
  } else {
    bName = data[0].businessName;
    return `${bName}`;
  }
};

const getColorChange = (data: any) => {
  let styleClass: any = "";
  if (data === "PENDING") {
    styleClass = "pendingClass";
  }
  if (data === "IN_PROGRESS") {
    styleClass = "acceptedClass";
  }
  if (data === "COMPLETED") {
    styleClass = "completedClass";
  }
  if (data === "REVIEWED") {
    styleClass = "reviewClass";
  }
  return styleClass;
};

function capitalizeFirstLetter(str: any) {
  let i,
    frags = str.toLowerCase().split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

const getDays = (day: number) => {
  if (day == 1) {
    return "Monday";
  } else if (day == 2) {
    return "Tuesday";
  } else if (day == 3) {
    return "Wednesday";
  } else if (day == 4) {
    return "Thursday";
  } else if (day == 5) {
    return "Friday";
  } else if (day == 6) {
    return "Saturday";
  } else if (day == 0) {
    return "Sunday";
  }
};

export const getDatesInRange = (startDate: any, endDate: any) => {
  const diffDays =
    (new Date(endDate).getTime() + 1 - new Date(startDate).getTime()) /
    1000 /
    60 /
    60 /
    24;
  if (Math.floor(diffDays) === 1) {
    return `${Math.floor(diffDays)} day`;
  } else {
    return `${Math.floor(diffDays)} days`;
  }
};

export const checkAbn = (str: string) => {
  const abnArray = Array.from(str);
  if (Number(abnArray[0]) < 1) return true;

  const { abnWeight } = Utils.Constants;
  const totalSum = abnArray.reduce(
    (sum: number, currVal: string, currIndex: number) => {
      if (currIndex === 0) {
        return sum + (Number(currVal) - 1) * abnWeight[currIndex];
      }
      return sum + Number(currVal) * abnWeight[currIndex];
    },
    0
  );
  return totalSum % 89 === 0;
};

const weights = [8, 7, 6, 5, 4, 3, 2, 1];

export const isValidACN = (str: string) => {
  if (!str) {
    return false;
  }

  // strip non-alphanumeric characters
  const acn = str.toString().replace(/[^a-z\d]/gi, "");

  // check if length is 9 digits
  if (acn.length !== 9) {
    return false;
  }

  // apply ato check method
  let sum = 0;
  for (let position = 0; position < weights.length; position += 1) {
    const weight = weights[position];
    const digit = parseInt(acn[position], 10);
    sum += weight * digit;
  }

  const checksum = (10 - (sum % 10)) % 10;

  return checksum === parseInt(acn[8], 10);
};

const scrollToElement = (elementId: string, extraMargin: number = 0) => {
  const element = document.getElementById(elementId);
  if (element) {
    let position = element.getBoundingClientRect();
    // scrolls to 20px above element
    window.scrollTo({
      top: position.left,
      left: position.top + window.scrollY - extraMargin,
      behavior: "smooth",
    });
  }
};

const isElementInViewport = (elementId: string) => {
  // Assuming you have an element with the ID "elementId"
  const element = document.getElementById(elementId);
  if (element) {
    const rect = element.getBoundingClientRect();

    const checkHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= checkHeight &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
};

const CommonFunction = {
  collectClass,
  setLocalStorage,
  convertTimeIntoSec,
  convertTimeIntoMin,
  convertTimeIntoHour,
  convertTimeIntoDay,
  convertTimeIntoWeek,
  getFileType,
  getNotificationTimeStatus,
  getCreatedDate,
  getEndDate,
  getTime,
  getSeparate,
  getBusinessName,
  getColorChange,
  capitalizeFirstLetter,
  getDays,
  getDatesInRange,
  checkAbn,
  isValidACN,
  scrollToElement,
  isElementInViewport,
};

export default CommonFunction;
