import AWS from "aws-sdk";
import AwsCred from "./AwsCred";
import Utils from ".";

const { bucketRegion, BUCKETNAME, bucketUrl } = AwsCred;

const myBucket = new AWS.S3({
  params: { Bucket: BUCKETNAME },
  region: bucketRegion,
});

// export const uploadFile = async (file: any) => {
//   const params: any = {
//     ACL: "public-read",
//     Body: file,
//     Bucket: BUCKETNAME,
//     Key: file.name,
//     ContentType: file.type,
//   };

//   return new Promise((resolve, reject) => {
//     myBucket
//       .upload(params, (err: any, data: any) => {
//         if (err) {
//           reject(err);
//         }
//         if (data) {
//           resolve(data);
//         }
//       })
//       .on("httpUploadProgress", () => {});
//   });
// };

//New function to upload profile

export const uploadFile = async (file: any) => {
  console.log("KSJDKSJD", file);

  const formData = new FormData();
  formData.append("file", file);

  return new Promise((resolve, reject) => {
    Utils.API.postApiCall(
      Utils.endPoint.uploadProfile,
      formData,
      (respData: any) => {
        let { data } = respData;
        console.log(respData, "What is this ");
        resolve({
          Location: `${bucketUrl}${data.data.image}`,
        });
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        reject(data);
      }
    );
  });
};

export const deleteFileAws = async (filename: string) => {
  const params: any = {
    Bucket: BUCKETNAME,
    Key: filename,
  };
  return new Promise((resolve, reject) => {
    myBucket
      .deleteObject(params, (err: any, data: any) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      })
      .on("httpUploadProgress", () => {});
  });
};
