/* eslint-disable no-global-assign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  useTheme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  Stack,
  Divider,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/system";
import LocalImages from "../../Utils/localImages";
import Modal from "@mui/material/Modal";
import { useStyles } from "./style";
import Utils from "../../Utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModel } from "../../model";
import { getNotificationListData, updateNotification } from "./action";
import CommonFunction from "../../Utils/commonFunctions";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import {
  changeTab,
  getJobDetailsData,
  getOtherJobDetailsData,
} from "../../screen/viewDetails/action";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "2rem auto",
  alignItems: "center",
  textAlign: "center",
});

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  color: "#333333",
  margin: "1% 0% 1% 0%",
});
const StyledMessageTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
  color: "#333333",
  margin: "0 0 0 0",
});

const StyledSubTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  color: "#828282",
  margin: "0% 0% 2% 0%",
});

const AvatarCircle = styled(Avatar)({
  backgroundColor: "#F2F2F2",
  color: "#F2F2F2",
  width: "46px",
  height: "46px",
});

type PropTypes = {
  ModalOpen: boolean;
  handleLogout?: any;
  setModalOpen?: any;
};

const NotificationModal = ({ ModalOpen, setModalOpen }: PropTypes) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(700));

  const [open, setOpen] = React.useState(false);

  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    if (userId) {
      dispatch(getNotificationListData());
      setInterval(() => dispatch(getNotificationListData()), 150000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { notificationListData } = useSelector(
    (state: ReducersModel) => state.notificationListingReducer
  );

  const allId =
    notificationListData &&
    notificationListData.map((el: any) => {
      return el?._id;
    });

  const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: "15rem",
      fontSize: "1.3rem",
      border: "1px solid #dadde9",
      marginLeft: "1020px",
      zIndex: "10000 !important",
      position: "relative",
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        background: "#fff",
        border: "1px solid #dadde9",
        display: "none",
      },
      // transform: isMobile
      //   ? "translate(170px, 0px) !important"
      //   : "translate(100px, 0px) !important",
    },
  }));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const NavToolTip = (props: any) => {
    return (
      <>
        <CustomToolTip
          onClose={handleTooltipClose}
          placement="bottom-end"
          disableFocusListener
          enterTouchDelay={0}
          title={
            <Stack direction="column" sx={{ width: "12rem" }}>
              <Typography
                onClick={(e: any) => {
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  dispatch(updateNotification(4, Array(props?.data?._id)));
                  e.stopPropagation();
                }}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".7rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.MarkRead}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Mark as unread
                </span>
              </Typography>
              <Typography
                onClick={(e: any) => {
                  dispatch(updateNotification(2, Array(props?.data?._id)));
                  e.stopPropagation();
                }}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".7rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.Remove}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Remove Notification
                </span>
              </Typography>
            </Stack>
          }
          arrow
        >
          {props.children}
        </CustomToolTip>
        {/* </ClickAwayListener> */}
      </>
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const ITEM_HEIGHT = 40;

  function LongMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleClose1 = (e: any) => {
      setAnchorEl(null);
      dispatch(updateNotification(4, Array(props?.data?._id)));
      e.stopPropagation();
    };
    const handleClose2 = (e: any) => {
      setAnchorEl(null);
      dispatch(updateNotification(2, Array(props?.data?._id)));
      e.stopPropagation();
    };

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ float: "right" }}
          >
            <img src={LocalImages.Horizontal_Dot} alt="" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
                marginTop: "20px",
                transitionDuration: "50ms",
              },
            }}
          >
            <Stack>
              <Typography
                onClick={handleClose1}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".4rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.MarkRead}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Mark as unread
                </span>
              </Typography>
              <Typography
                onClick={handleClose2}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".4rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.Remove}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Remove Notification
                </span>
              </Typography>
            </Stack>
          </Menu>
        </>
      </ClickAwayListener>
    );
  }

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      open={ModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ background: "transparent" }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "400px",
          minHeight: "100px",
          backgroundColor: "#fff",
          outline: "none",
          position: "fixed",
          top: "10%",
          right: "10%",
          overflow: "auto",
          padding: "1.6rem 0rem",
          "&.MuiBackdrop-root-MuiModal-backdrop": {
            background: "transparent",
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            left: "5%",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
          }}
        >
          <Typography>Notifications</Typography>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              dispatch(updateNotification(1, allId));
            }}
            disabled={notificationListData.length === 0}
          >
            Mark as read
          </Button>
        </Box>
        {notificationListData && notificationListData.length > 0 ? (
          <Box
            sx={{
              overflow: "auto",
              height: "400px",
            }}
          >
            {notificationListData.length > 0 &&
              notificationListData.map((el: any, i: any) => {
                return (
                  <React.Fragment key={i}>
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                      }}
                      onClick={() => {
                        setModalOpen(false);
                        {
                          if (
                            el?.type === "QUOTE_RECEIVED" ||
                            el?.type === "QUOTE_CONFIRM" ||
                            el?.type === "REQUEST_REVIEWED"
                          ) {
                            localStorage.setItem(
                              "requestType",
                              el?.requestType
                            );
                            localStorage.setItem("myId", el?.subjectId);
                            history.push(Utils.Pathname.JobDetails);
                            dispatch(
                              getJobDetailsData(localStorage.getItem("myId"))
                            );
                          } else if (el?.type === "CREATE_JOB" || el?.type === "EXPIRED_JOB" || el?.type === "EXPIRING_JOB") {
                            localStorage.setItem("myId", el?.subjectId);
                            history.push(Utils.Pathname.JobDetails);
                            dispatch(
                              getOtherJobDetailsData(
                                localStorage.getItem("myId")
                              )
                            );
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (el?.type === "COMMENTS") {
                            dispatch(changeTab(1));
                            localStorage.setItem("myId", el?.subjectId);
                            history.push(Utils.Pathname.JobDetails);
                            dispatch(
                              getOtherJobDetailsData(
                                localStorage.getItem("myId"),
                                (status = "COMPLETED")
                              )
                            );
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (
                            el?.type === "ASSIGN_JOB" ||
                            el?.type === "REQUEST_REVIEWED"
                          ) {
                            dispatch(changeTab(1));
                            localStorage.setItem(
                              "requestType",
                              el?.requestType
                            );
                            localStorage.setItem("myId", el?.subjectId);
                            history.push(Utils.Pathname.JobDetails);
                            dispatch(
                              getOtherJobDetailsData(
                                localStorage.getItem("myId")
                              )
                            );
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (el?.type === "SCHEDULE_JOB") {
                            history.push(Utils.Pathname.DASHBOARD);
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (el?.type === "ADD_TEAM") {
                            history.push(Utils.Pathname.Settings.Team);
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (el?.type === "RATE_CARD_CREATED") {
                            history.push(Utils.Pathname.DASHBOARD);
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                            // else if (el?.type === "EXPIRING_JOB") {
                            //   history.push(Utils.Pathname.DASHBOARD);
                            //   if (el?.isRead === false) {
                            //     dispatch(updateNotification(1, Array(el?._id)));
                            //   }
                            // } 
                          } else if (el?.type === "ROLE_PERMISSION_UPDATED") {
                            history.push(Utils.Pathname.Settings.Team);
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                          } else if (el?.type === "COMPLETE_JOB") {
                            history.push(Utils.Pathname.HEADER.REQUESTS, 5);
                            if (el?.isRead === false) {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }
                            // else if (el?.type === "COMMENTS") {
                            //   dispatch(changeTab(1));
                            //   localStorage.setItem("myId", el?.subjectId);
                            //   history.push(Utils.Pathname.JobDetails);
                            //   if (el?.isRead === false) {
                            //     dispatch(updateNotification(1, Array(el?._id)));
                            //   }
                            //   dispatch(
                            //     getOtherJobDetailsData(
                            //       localStorage.getItem("myId"),
                            //       (status = "COMPLETED")
                            //     )
                            //   );
                            // }
                          }
                        }
                      }}
                    >
                      <Box>
                        <Avatar
                          src={
                            el?.businessProfilePicture
                              ? el?.businessProfilePicture
                              : LocalImages.Circle
                          }
                          sx={{
                            margin: "25px .75rem 0px 0px",
                            height: "45px",
                            width: "45px",
                          }}
                          variant="circular"
                          alt="avatar"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          marginBottom: "16px",
                          marginTop: "5%",
                        }}
                      >
                        <StyledMessageTypography>
                          {el?.title}
                          {el?.isRead === false ? (
                            <Button
                              sx={{
                                float: "right",
                                height: "15px",
                                [theme.breakpoints.down(600)]: {
                                  minWidth: "10px",
                                },
                              }}
                              onClick={() => {
                                dispatch(updateNotification(1, Array(el?._id)));
                              }}
                            >
                              <img
                                src={LocalImages.BlueDot}
                                alt=""
                                height="8px"
                                width="8px"
                                style={{
                                  float: "right",
                                  paddingTop: "12px",
                                }}
                              />
                            </Button>
                          ) : (
                            <>
                              {!isMobile ? (
                                <NavToolTip dispatch={dispatch} data={el}>
                                  <Button
                                    onClick={(e: any) => e.stopPropagation()}
                                    // onClick={handleClick}
                                    sx={() => ({
                                      float: "right",
                                      fontSize: "16px",
                                      borderRadius: 0,
                                      color: "#4f4f4f",
                                      "&:hover": {
                                        background: "transparent",
                                        color: "#4f4f4f",
                                      },
                                    })}
                                    disableRipple
                                  >
                                    <img
                                      src={LocalImages.Horizontal_Dot}
                                      alt=""
                                    />
                                  </Button>
                                </NavToolTip>
                              ) : (
                                <LongMenu data={el} />
                              )}
                            </>
                          )}
                        </StyledMessageTypography>
                        <StyledTypography sx={{ width: "80%" }}>
                          {el?.message.replace("_", " ")}
                        </StyledTypography>
                        <StyledSubTypography>
                          {`${CommonFunction.getNotificationTimeStatus(
                            el.createdAt
                          )} ago`}
                        </StyledSubTypography>
                      </Box>
                    </Box>
                    <Divider sx={{ border: "1px solid #F2F2F2" }} />
                  </React.Fragment>
                );
              })}
          </Box>
        ) : (
          <Box className={classes.modalBox}>
            <StyledBox>
              <AvatarCircle>
                <img src={LocalImages.Notification_dot} alt="arrow" />
              </AvatarCircle>
              <StyledTypography>You’re All Caught Up</StyledTypography>
              <StyledSubTypography>
                Notifications about your activity will <br />
                show up here.
              </StyledSubTypography>
            </StyledBox>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default NotificationModal;
