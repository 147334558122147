const EndPoint = {
  login: "/v1/client/login",
  signup: "/v1/client/signup",
  resendlink: "/v1/client/resend-link",
  forgotpassword: "/v1/client/forgot-password",
  resetpassword: "/v1/client/reset-password",
  verifyaccount: "/v1/client/verify-account",
  profile: "/v1/client/profile",
  profilecomplete: "/v1/client/profile-complete",
  logout: "/v1/client/logout",
  roles: "/v1/common/dropdown",
  editCurrentProfile: "/v1/client/edit-profile",
  editBusinessProfile: "/v1/client/edit-business-profile",
  admininvite: "/v1/client/admin-invite",
  adminlist: "/v1/client/admin-list",
  deleteadmin: "/v1/client/delete-admin",
  editpermission: "/v1/client/edit-permission",
  financialOnboarding: "/v1/client/financial-onboarding",
  notificationSettings: "/v1/client/notification-setting",
  notificationList: "/v1/common/notification-list",
  readUnreadNotification: "/v1/common/notifications-data-update",
  // inside RFQ PROJECT selct service provider
  selectServiceProvider: "/v1/common/company-search",
  rfqJobs: "/v1/client/rfq-jobs",
  clientJobs: "/v1/client/jobs",
  jobDetails: "/v1/client/job-detail",
  otherJobDetails: "/v1/client/review-booked-job-detail",
  deleteJob: "/v1/admin/delete-job",
  acceptJob: "/v1/client/job-status",
  countJob: "/v1/client/job-counter",
  tooltip: "/v1/common/tool-tip",
  serviceProviderList: "/v1/client/service-provider-list",
  serviceProviderSearch: "/v1/client/service-provider-search",
  createEmergencyCallOut: "/v1/client/rapid-response-jobs",
  createMaintenanceServices: "/v1/client/maintenance-service-jobs",
  clientRate: "/v1/common/client-rate",
  projectSupport: "/v1/client/project-service-jobs",
  scheduleBookings: "/v1/common/schedule-booking",
  guestCompanyListting: "/v1/common/guest-company-client-list",
  saveCompany: "/v1/common/save-company",
  companyListing: "/v1/common/company-client-list",
  savedCompanyListing: "/v1/common/saved-company-list",
  sendEnquiry: "/v1/common/send-enquiry-form",
  companyDetails: "/v1/common/company-details",
  getComments: "/v1/jobs/comment",
  postComments: "/v1/jobs/comment",
  uploadProfile: "/v1/common/media-upload",
};

export default EndPoint;
